// Check if user is a platform-level admin. Used for displaying beta elements in nav and other admin features.
export function useIsPlatformAdmin() {
  const user = useSupabaseUser()
  if (!user) { return false }
  if (user && user.value && user.value.email) {
    const email = user?.value?.email?.toLowerCase()
    return email.endsWith('soil-link.co.uk') || email.endsWith('soillink.email')
  }
  return false
}
